.NavbarComponent {
    border-bottom-style: solid;
    border-bottom-color: #A98D54;
  }
  
.navbar .navbar-nav .nav-link:hover {
    color: #000000;
    
}
.navbar .navbar-nav .nav-link {
    color: #000000;
    font-size: 1.1em;
    
}
@media only screen and (min-width: 992px) {
    .navbar {
      padding: 0;
    }
    .navbar .navbar-brand {
      padding: 0 0.7em;
    }
    .navbar .navbar-nav .nav-link {
      padding: 1em 0;
    }
    .navbar .navbar-nav .nav-item {
      
      margin: 0 1em;
    }
    
}
.navbar .navbar-nav .nav-item {
    position: relative;
    font-size: 16px;
    
}
.navbar .navbar-nav .nav-item::after {
    position: absolute;
    margin: auto;
    background-color: #A98D54;
    width: 0%;
    content: "";
    height: 8px;
}
.navbar .navbar-nav .nav-item:hover::after {
    width: 100%;
}
  
.btn-enquiries {
    background-color: #8c734b;
    font-weight: bold;
    border: none;
    color: white;
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 4px 2px;
    cursor: pointer;
}
.btn-enquiries:hover {
  background-color: #8c734b;
  font-weight: bold;
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
}

.color-TankardGray {
    color: #90734f;
}

.badge-lan { 
  background-color: #857c6e;
  color: white;
  padding: 4px 4px;
  text-align: center;
  border-radius: 5px;
}

